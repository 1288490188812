<script>
    export let componentData = "default componentData test...";

</script>

<div class="sample-component">
    <p>Locations object data : {JSON.stringify(componentData)}</p>
</div>

<style>
    .sample-component {
        /* styles */
    }
</style>
